import "./style.scss";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { mail } from "ionicons/icons";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { loginWithLink } from "../store/session";

interface LoginRouteState {
  url?: string;
  phone?: string;
  email?: string;
  redirectPath?: string;
}

// TODO: Migrate this route to V2 and remove this file
// Expose loginWithLink function to V2 via provider
const EmailVerify: FC<{}> = () => {
  const [email, setEmail] = useState<string>("");

  const location = useLocation();
  const dispatch = useDispatch();
  const emailToVerify = localStorage.getItem("emailToVerify");

  useEffect(() => {
    if (emailToVerify) {
      verify();
    }
  }, [emailToVerify]);

  const onEmailChange = (event: Event) => {
    setEmail(event.target?.["value"]);
  };

  const verify = async () => {
    const { url } = (location.state || {}) as LoginRouteState;
    if (!email && emailToVerify) {
      return dispatch(loginWithLink(url as string, emailToVerify));
    }
    dispatch(loginWithLink(url as string, email));
  };

  const onKeyUp = (event: KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      verify();
    }
  };

  return (
    <IonPage>
      <IonContent class="login-content ion-padding ion-text-center">
        <IonToolbar color="transparent">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/welcome/login" mode="ios" text="Login" color="light" />
          </IonButtons>
        </IonToolbar>
        <IonRow class="ion-align-items-center">
          <IonCol align-self-center sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
            <div style={{ margin: "60px 0" }}>
              <h4>Welcome</h4>
              <h4 className="ion-margin-bottom">Please enter your email to verify</h4>
            </div>

            <IonItem lines="none">
              <IonIcon icon={mail} slot="start" color="medium" />
              <IonInput
                class="ion-text-left"
                type="email"
                placeholder="Email"
                onIonChange={onEmailChange}
                value={email}
                onKeyUp={onKeyUp}
              />
            </IonItem>
            <IonButton
              color="primary"
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom login-button"
              onClick={verify}
              disabled={!email}
              id="login-button"
            >
              Verify
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export { EmailVerify };
