import { type LocationTrackingMode } from "@src/app/api/cbh-main-api/cbh-main-api.types";
import { locationTrackingHelper } from "@src/app/locationTracking/locationTracking.helper";
import { TripTrackingTrigger } from "@src/app/locationTracking/locationTracking.types";
import { useTrackWorkerLeftEarly } from "@src/app/locationTracking/useTrackWorkerLeftEarly";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { useCallback } from "react";

import { locationService } from "../openShifts/urgentShifts/locationService";

/**
 * FIXME: Move this hook into appV2 when locationTrackingHelper is moved to appV2
 */
export function useRegisterLocationUpdateListener() {
  const { setLocationTrackingConfig } = useTrackWorkerLeftEarly();

  return useCallback(
    async (agentId: string) => {
      if (!isCapacitorPlatform()) {
        // Return a listener remove instead of undefined to keep
        // consistency and simplify code that makes use of this function
        return () => {};
      }

      return await locationService.addLocationListener(async (params) => {
        const { location, user } = params;
        await locationTrackingHelper.handleBackgroundMode(async () => {
          await locationTrackingHelper.handleTrackingModeChange({
            agentId,
            trigger: TripTrackingTrigger.locationUpdate,
            onLocationTrackingModeChange: (trackingConfiguration: LocationTrackingMode) => {
              setLocationTrackingConfig({
                location,
                user,
                trackingConfiguration,
              });
            },
          });
        });
      });
    },
    [setLocationTrackingConfig]
  );
}
